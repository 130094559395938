import { CreateIcon, DeleteIcon, EditIcon } from "ds/icons";
import { NewBadge } from "ds_legacy/components/Badge/index";
import ConfirmationDialog from "ds_legacy/components/ConfirmationDialog";
import { useConfirmationDialog } from "ds_legacy/components/ConfirmationDialog/useConfirmationDialog";
import { DateSelectorProps } from "ds_legacy/components/DatePickerInputField";
import DatePickerInputField from "ds_legacy/components/DatePickerInputField/lazy";
import RSButton from "ds_legacy/components/RSButton";
import TextInputField, {
  TextInputFieldProps,
} from "ds_legacy/components/TextInputField";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import { Subheading } from "ds_legacy/materials/typography";
import { WithFormOnChange } from "dsl/ecosystems/PatientAssessment/AssessmentStep";
import {
  BarthelAssessmentModal,
  BarthelAssessmentModalProps,
} from "dsl/organisms/IndependenceScale/BarthelAssessment/index";
import {
  EarlyRehabAssessmentModal,
  EarlyRehabAssessmentModalProps,
} from "dsl/organisms/IndependenceScale/EarlyRehabAssessment/index";
import {
  SingerAssessmentModal,
  SingerAssessmentModalProps,
} from "dsl/organisms/IndependenceScale/SingerAssessment/index";
import { ComponentType, useState } from "react";
import { FormWatcher, Show } from "react-forms-state";
import { useTranslations } from "translations";

type AssessmentProps = {
  datePickerProps?: Omit<DateSelectorProps, "onChange">;
  textInputProps?: TextInputFieldProps;
  variant: "singer" | "barthel" | "early_rehab";
  withNewBadge?: boolean;
} & WithFormOnChange;

const configModals: Record<
  "singer" | "barthel" | "early_rehab",
  | ComponentType<SingerAssessmentModalProps>
  | ComponentType<EarlyRehabAssessmentModalProps>
  | ComponentType<BarthelAssessmentModalProps>
> = {
  singer: SingerAssessmentModal,
  barthel: BarthelAssessmentModal,
  early_rehab: EarlyRehabAssessmentModal,
};

function Assessment({
  datePickerProps,
  onChange,
  textInputProps,
  variant,
  withNewBadge,
}: AssessmentProps) {
  const translations = useTranslations();
  const [confirmProps, confirm] = useConfirmationDialog();
  const [isOpen, setIsOpen] = useState(false);

  const Modal = configModals[variant];

  return (
    <Show elementName={`${variant}_assessment`}>
      <FormWatcher watchPath="">
        {({ watchedValue }) => {
          const hasForm = watchedValue?.[`${variant}_assessment_form`];

          return (
            <>
              <VerticalLayout
                as="fieldset"
                boxSizing="border-box"
                overflow="visible"
                width="100%"
                margin={margin(2, 0, 0, 0)}
                style={{ position: "relative" }}
              >
                <Subheading as="legend" margin={margin(0, 2)} bold>
                  <HorizontalLayout aligned>
                    {variant === "singer"
                      ? translations.patient.medicalDiagnosis
                          .singerPatientProfile.title
                      : variant === "barthel"
                      ? translations.patient.medicalDiagnosis.dependencyLevel
                      : translations.patient.medicalDiagnosis.earlyRehabForm}
                    {withNewBadge && (
                      <NewBadge marginWrapper={margin(0, 0, 0, 1)} />
                    )}
                  </HorizontalLayout>
                </Subheading>

                <HorizontalLayout
                  boxSizing="border-box"
                  overflow="visible"
                  padding={padding(0, 0, 0, 2)}
                  gap={padding(2)}
                  style={{ alignItems: "baseline" }}
                >
                  <DatePickerInputField
                    disabled={hasForm}
                    disableFuture
                    elementName={`${variant}_assessment_date`}
                    hasCustomValidation
                    label={translations.patient.medicalDiagnosis.assessmentDate}
                    inputSx={{ width: dp(165) }}
                    {...datePickerProps}
                  />
                  <TextInputField
                    disabled={hasForm}
                    elementName={`${variant}_assessment_score`}
                    hasCustomValidation
                    label={
                      variant === "singer"
                        ? translations.patient.medicalDiagnosis
                            .singerPatientProfile.singerSum
                        : variant === "barthel"
                        ? translations.patient.medicalDiagnosis
                            .barthelIndexPlaceholder
                        : translations.patient.medicalDiagnosis
                            .earlyRehabBarthelIndexPlaceholder
                    }
                    sideMutation={(
                      _value: string,
                      mutate: (v: any, name: string) => void,
                    ) => {
                      mutate(null, `${variant}_assessment_form`);
                    }}
                    textInputType="number"
                    style={{ margin: 0, width: dp(165) }}
                    {...textInputProps}
                  />
                  <HorizontalLayout
                    overflow="visible"
                    boxSizing="border-box"
                    gap={margin(1)}
                    style={{ alignItems: "baseline" }}
                  >
                    <RSButton
                      color="primary"
                      id={
                        hasForm
                          ? `edit_${variant}_assessment`
                          : `add_${variant}_assessment`
                      }
                      LeftIcon={hasForm ? EditIcon : CreateIcon}
                      loading="na"
                      onClick={() => setIsOpen(true)}
                      size="small"
                      variant="text"
                    >
                      {hasForm
                        ? translations.patient.medicalDiagnosis
                            .singerPatientProfile.buttonEdit
                        : translations.patient.medicalDiagnosis
                            .singerPatientProfile.buttonFill}
                    </RSButton>
                    {hasForm && (
                      <RSButton
                        color="primary"
                        id={`delete_${variant}_assessment`}
                        LeftIcon={DeleteIcon}
                        loading="na"
                        onClick={async () => {
                          const confirmed = await confirm({
                            title:
                              translations.patient.medicalDiagnosis
                                .singerPatientProfile.confirmationDelete,
                            submitText: translations.actions.confirm,
                            cancelText: translations.actions.cancel,
                          });
                          if (!confirmed) return;

                          onChange(null, `${variant}_assessment_form`);
                          onChange(null, `${variant}_assessment_score`);
                          onChange(null, `${variant}_assessment_date`);
                        }}
                        size="small"
                        variant="text"
                      >
                        {
                          translations.patient.medicalDiagnosis
                            .singerPatientProfile.buttonDelete
                        }
                      </RSButton>
                    )}
                  </HorizontalLayout>
                </HorizontalLayout>
              </VerticalLayout>
              {isOpen ? (
                <Modal
                  isOpen={isOpen}
                  onCancel={() => setIsOpen(false)}
                  onSubmit={({ date, form, score }) => {
                    onChange(form, `${variant}_assessment_form`);
                    onChange(score, `${variant}_assessment_score`);
                    onChange(date, `${variant}_assessment_date`);
                    setIsOpen(false);
                  }}
                  initialValues={{
                    form: watchedValue[`${variant}_assessment_form`] ?? {},
                    date: watchedValue[`${variant}_assessment_date`] ?? null,
                  }}
                />
              ) : null}
            </>
          );
        }}
      </FormWatcher>
      <ConfirmationDialog {...confirmProps} />
    </Show>
  );
}

export const SingerAssessment = (props: Omit<AssessmentProps, "variant">) => {
  return <Assessment variant="singer" {...props} />;
};
export const SingerAssessmentWithBadge = (
  props: Omit<AssessmentProps, "variant">,
) => {
  return <Assessment variant="singer" {...props} withNewBadge />;
};

export const BarthelAssessment = (props: Omit<AssessmentProps, "variant">) => {
  return <Assessment variant="barthel" {...props} />;
};

export const EarlyRehabAssessment = (
  props: Omit<AssessmentProps, "variant">,
) => {
  return <Assessment variant="early_rehab" {...props} />;
};
